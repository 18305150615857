const mobileButton = document.querySelector(".mobile-button");
const mobileNav = document.querySelector(".mobile-nav");

mobileButton.addEventListener("click", function () {

    mobileNav.classList.toggle("open");

})

window.addEventListener("resize", function () {

    if(mobileNav.classList.contains("open")) {
        mobileNav.classList.remove("open");
    }

})